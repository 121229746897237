import { Injectable } from '@angular/core';
import { initializeApp } from 'firebase/app';
import { Auth, getAuth, signInWithCustomToken } from 'firebase/auth';
import { Observable } from 'rxjs';
import {
  Database,
  getDatabase,
  ref,
  query,
  orderByChild,
  equalTo,
  onValue,
} from 'firebase/database';
import {
  Firestore,
  getFirestore,
  doc,
  collection,
  onSnapshot,
} from 'firebase/firestore';
import { environment } from '@env/environment';

/**
 * Service to handle authentication-related operations using Firebase.
 */
@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  private auth: Auth;
  private db: Database;
  private fStore: Firestore;

  /**
   * Initializes the FirebaseService and Firebase app.
   */
  constructor() {
    const app = initializeApp(environment.firebase);
    this.auth = getAuth(app);
    this.db = getDatabase(app);
    this.fStore = getFirestore(app);
  }

  /**
   * Signs in with a Firebase custom token.
   * @param {string} token - The Firebase custom token.
   * @returns {Promise<any>} A promise that resolves with the sign-in response or rejects with an error.
   */
  async signInWithFirebaseToken(token: string): Promise<any> {
    try {
      const response = await signInWithCustomToken(this.auth, token);
      // console.log('Signed in successfully:', response);
      return response;
    } catch (error) {
      console.error('Error signing in with custom token:', error);
      throw error;
    }
  }

  /**
   * Signs out from Firebase.
   * @returns {Promise<void>} A promise that resolves when the sign-out process is complete or rejects with an error.
   */
  async signOutFromFirebase(): Promise<void> {
    try {
      await this.auth.signOut();
      // console.log('Signed out successfully!');
    } catch (error) {
      console.error('Error signing out:', error);
      throw error;
    }
  }

  /**
   * Listens for changes in the delivery zone from Firebase.
   * @returns {Observable<any>} An observable that emits the delivery zone data.
   */
  public zoneListener(): Observable<any> {
    return new Observable((observer) => {
      const zoneRef = ref(this.db, 'delivery_zone');
      const unsubscribe = onValue(zoneRef, (snapshot) => {
        const data = snapshot.val();
        observer.next(data);
      });

      // Cleanup function to unsubscribe from the listener
      return () => {
        unsubscribe(); // Unsubscribe to prevent memory leaks
      };
    });
  }

  /**
   * Gets task data from Firestore for a specific organization.
   * @param {number} orgId - The organization ID.
   * @returns {Observable<any[]>} - An observable of task data.
   */
  getTaskData(orgId: number): Observable<any[]> {
    const orgDocRef = doc(this.fStore, `orgtasks/ORG${orgId}`);
    const tasksCollectionRef = collection(orgDocRef, 'tasks');

    return new Observable((observer) => {
      const unsubscribe = onSnapshot(
        tasksCollectionRef,
        (querySnapshot) => {
          const tasks: any[] = [];
          querySnapshot.forEach((doc) => {
            tasks.push({ id: doc.id, ...doc.data() });
          });
          observer.next(tasks); // Emit the updated data each time there is a change
        },
        (error) => {
          console.error('Error fetching tasks:', error);
          observer.error(error);
        }
      );

      // Return a cleanup function to unsubscribe from the snapshot listener
      return () => unsubscribe();
    });
  }

  /**
   * Listens for driver data based on state code and zone ID.
   * @param {string} state_code - The state code.
   * @param {number} zone_id - The zone ID.
   * @returns {Observable<any>} An observable that emits the driver data.
   */
  driverListener(state_code: string, zone_id: number): Observable<any> {
    return new Observable((observer) => {
      const driversRef = ref(this.db, 'drivers');
      const driversQuery = query(
        driversRef,
        orderByChild('zone'),
        equalTo(zone_id)
      );

      // Set up the listener
      const unsubscribe = onValue(
        driversQuery,
        (snapshot) => {
          const data = snapshot.val();
          observer.next(data);
        },
        (error) => {
          console.error('Error fetching drivers:', error);
          observer.error(error);
        }
      );

      // Cleanup function to unsubscribe from the listener
      return () => {
        unsubscribe(); // Call the unsubscribe function to clean up
      };
    });
  }

  /**
   * Gets Onfleet driver details based on Onfleet ID.
   * @param {string} onfleetId - The Onfleet driver ID.
   * @returns {Observable<any>} An observable that emits driver details.
   */
  driverDetails(onfleetId: string): Observable<any> {
    const drvrDtlsRef = ref(this.db, 'drivers/' + onfleetId);

    return new Observable((observer) => {
      const unsubscribe = onValue(
        drvrDtlsRef,
        (snapshot) => {
          observer.next(snapshot.val());
        },
        (error) => {
          console.error('Error fetching driver details:', error);
          observer.error(error);
        }
      );

      // Cleanup function to unsubscribe from the listener
      return () => {
        unsubscribe();
      };
    });
  }
}
